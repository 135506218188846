<template>
  <div class="relative">
    <div class="absolute inset-0 bg-gray-800 h-128" style="z-index: -1;"></div>
    <div class="flex flex-col items-center mx-auto my-24 max-w-7xl">
      <h1 class="text-3xl font-extrabold text-gray-100">
        Relax, let's do the work
      </h1>
      <p class="mt-5 text-gray-300">
        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ab,
        consequatur rem suscipit reprehenderit similique sit nihil delectus
        quisquam!
      </p>

      <div class="w-full lg:hidden">
        <table class="w-11/12 mx-auto mt-10 bg-white rounded-lg shadow-xl">
          <tbody>
            <tr>
              <td colspan="2" class="relative">
                <div
                  class="absolute inset-x-0 flex items-center justify-center w-full transform -translate-y-1/2"
                >
                  <span
                    class="flex items-center justify-center px-4 py-1 text-sm text-white rounded-full left-5/12 bg-lime-800"
                    >ROOFONE</span
                  >
                </div>
                <div class="px-5 py-5">
                  <h2 class="mt-5 font-semibold text-center text-gray-900">
                    Buying with roofone
                  </h2>
                  <div class="mt-1 text-center">
                    <span class="text-3xl font-semibold text-gray-900"
                      >3-4%</span
                    >
                  </div>
                  <p class="max-w-xs mt-2 text-sm text-gray-500">
                    Covers marketplace fee,legal, escrow, and broker fees.
                    Excludes VAT of 7.5%
                  </p>
                </div>
              </td>
            </tr>
            <tr>
              <td class="px-5 py-5 border-t border-gray-200">
                Listing price (Example)
              </td>
              <td
                class="px-5 py-5 text-lg font-semibold text-center border-t border-l border-gray-200"
              >
                N3,000,000
              </td>
            </tr>
            <tr>
              <td
                colspan="2"
                class="px-5 py-2 text-xl font-semibold bg-gray-100 border-t border-gray-200"
              >
                BUYING COST
              </td>
              <td class="bg-gray-100 border-t border-gray-200"></td>
            </tr>
            <tr>
              <td class="px-5 py-5 border-t border-gray-200">
                Average days to bid and inspect property
              </td>
              <td
                class="px-5 py-5 text-lg font-semibold text-center border-t border-l border-gray-200"
              >
                1-5 days
              </td>
            </tr>
            <tr>
              <td class="px-5 py-5 border-t border-gray-200">
                Escrow
              </td>
              <td
                class="px-5 py-4 text-lg font-semibold text-center border-t border-l border-gray-200"
              >
                <div class="flex justify-center">
                  <CheckIcon class="w-7 h-7 text-lime-600" />
                </div>
              </td>
            </tr>
            <tr>
              <td class="px-5 py-5 border-t border-gray-200">
                Verification and search fees
              </td>
              <td
                class="px-5 py-5 text-lg font-semibold text-center border-t border-l border-gray-200"
              >
                N0
              </td>
            </tr>
            <tr>
              <td class="px-5 py-5 border-t border-gray-200">
                Legal fees
              </td>
              <td
                class="px-5 py-5 text-lg font-semibold text-center border-t border-l border-gray-200"
              >
                N0
              </td>
            </tr>
            <tr>
              <td class="px-5 py-5 border-t border-gray-200">
                Data analytics and estimating returns
              </td>
              <td
                class="px-5 py-4 text-lg font-semibold text-center border-t border-l border-gray-200"
              >
                <div class="flex justify-center">
                  <CheckIcon class="w-7 h-7 text-lime-600" />
                </div>
              </td>
            </tr>
            <tr>
              <td class="px-5 py-5 border-t border-gray-200">
                Average days to close a transaction
              </td>
              <td
                class="px-5 py-4 text-lg font-semibold text-center border-t border-l border-gray-200"
              >
                <div class="flex justify-center">
                  <CheckIcon class="w-7 h-7 text-lime-600" />
                </div>
              </td>
            </tr>
            <tr>
              <td
                colspan="2"
                class="px-5 py-2 text-xl font-semibold bg-gray-100 border-t border-gray-200"
              >
                TRANSACTION COST
              </td>
              <td class="bg-gray-100 border-t border-gray-200"></td>
            </tr>
            <tr>
              <td class="px-5 py-5 border-t border-gray-200">
                Roofone fee
              </td>
              <td
                class="px-5 py-5 text-lg font-semibold text-center border-t border-l border-gray-200"
              >
                N900,000
              </td>
            </tr>
            <tr>
              <td class="px-5 py-5 border-t border-gray-200">
                Agency
              </td>
              <td
                class="px-5 py-4 text-lg font-semibold text-center border-t border-l border-gray-200"
              >
                <div class="flex justify-center">
                  <XIcon class="w-7 h-7 text-lime-600" />
                </div>
              </td>
            </tr>
            <tr>
              <td class="px-5 py-5 border-t border-gray-200">
                Overall cost of buying
              </td>
              <td
                class="px-5 py-5 text-lg font-semibold text-center border-t border-l border-gray-200"
              >
                N900,000
              </td>
            </tr>
          </tbody>
        </table>

        <table class="w-11/12 mx-auto mt-32 bg-white rounded-lg shadow-xl">
          <tbody>
            <tr>
              <td colspan="2">
                <div class="px-5 py-5">
                  <h2 class="mt-5 font-semibold text-center text-gray-900">
                    Buying with roofone
                  </h2>
                  <div class="mt-1 text-center">
                    <span class="text-3xl font-semibold text-gray-900"
                      >3-4%</span
                    >
                  </div>
                  <p class="max-w-xs mt-2 text-sm text-gray-500">
                    Covers marketplace fee,legal, escrow, and broker fees.
                    Excludes VAT of 7.5%
                  </p>
                </div>
              </td>
            </tr>
            <tr>
              <td class="px-5 py-5 border-t border-gray-200">
                Listing price (Example)
              </td>
              <td
                class="px-5 py-5 text-lg font-semibold text-center border-t border-l border-gray-200"
              >
                N3,000,000
              </td>
            </tr>
            <tr>
              <td
                colspan="2"
                class="px-5 py-2 text-xl font-semibold bg-gray-100 border-t border-gray-200"
              >
                BUYING COST
              </td>
              <td class="bg-gray-100 border-t border-gray-200"></td>
            </tr>
            <tr>
              <td class="px-5 py-5 border-t border-gray-200">
                Average days to bid and inspect property
              </td>
              <td
                class="px-5 py-5 text-lg font-semibold text-center border-t border-l border-gray-200"
              >
                1-5 days
              </td>
            </tr>
            <tr>
              <td class="px-5 py-5 border-t border-gray-200">
                Escrow
              </td>
              <td
                class="px-5 py-4 text-lg font-semibold text-center border-t border-l border-gray-200"
              >
                <div class="flex justify-center">
                  <CheckIcon class="w-7 h-7 text-lime-600" />
                </div>
              </td>
            </tr>
            <tr>
              <td class="px-5 py-5 border-t border-gray-200">
                Verification and search fees
              </td>
              <td
                class="px-5 py-5 text-lg font-semibold text-center border-t border-l border-gray-200"
              >
                N0
              </td>
            </tr>
            <tr>
              <td class="px-5 py-5 border-t border-gray-200">
                Legal fees
              </td>
              <td
                class="px-5 py-5 text-lg font-semibold text-center border-t border-l border-gray-200"
              >
                N0
              </td>
            </tr>
            <tr>
              <td class="px-5 py-5 border-t border-gray-200">
                Data analytics and estimating returns
              </td>
              <td
                class="px-5 py-4 text-lg font-semibold text-center border-t border-l border-gray-200"
              >
                <div class="flex justify-center">
                  <CheckIcon class="w-7 h-7 text-lime-600" />
                </div>
              </td>
            </tr>
            <tr>
              <td class="px-5 py-5 border-t border-gray-200">
                Average days to close a transaction
              </td>
              <td
                class="px-5 py-4 text-lg font-semibold text-center border-t border-l border-gray-200"
              >
                <div class="flex justify-center">
                  <CheckIcon class="w-7 h-7 text-lime-600" />
                </div>
              </td>
            </tr>
            <tr>
              <td
                colspan="2"
                class="px-5 py-2 text-xl font-semibold bg-gray-100 border-t border-gray-200"
              >
                TRANSACTION COST
              </td>
              <td class="bg-gray-100 border-t border-gray-200"></td>
            </tr>
            <tr>
              <td class="px-5 py-5 border-t border-gray-200">
                Roofone fee
              </td>
              <td
                class="px-5 py-5 text-lg font-semibold text-center border-t border-l border-gray-200"
              >
                N900,000
              </td>
            </tr>
            <tr>
              <td class="px-5 py-5 border-t border-gray-200">
                Agency
              </td>
              <td
                class="px-5 py-4 text-lg font-semibold text-center border-t border-l border-gray-200"
              >
                <div class="flex justify-center">
                  <XIcon class="w-7 h-7 text-lime-600" />
                </div>
              </td>
            </tr>
            <tr>
              <td class="px-5 py-5 border-t border-gray-200">
                Overall cost of buying
              </td>
              <td
                class="px-5 py-5 text-lg font-semibold text-center border-t border-l border-gray-200"
              >
                N900,000
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="hidden lg:block">
        <div class="mt-20 bg-white rounded-lg shadow-lg">
          <table class="relative w-full">
            <!-- <colgroup>
            <col />
            <col class="border-2 border-lime-600" />
          </colgroup> -->
            <tbody>
              <tr>
                <td></td>
                <td class="relative px-5 border-l border-gray-200">
                  <div
                    class="absolute inset-x-0 flex items-center justify-center w-full transform -translate-y-1/2"
                  >
                    <span
                      class="flex items-center justify-center px-4 py-1 text-sm text-white rounded-full left-5/12 bg-lime-800"
                      >ROOFONE</span
                    >
                  </div>
                  <div class="px-5 py-5">
                    <h2 class="mt-5 font-semibold text-center text-gray-900">
                      Buying with roofone
                    </h2>
                    <div class="mt-1 text-center">
                      <span class="text-3xl font-semibold text-gray-900"
                        >3-4%</span
                      >
                    </div>
                    <p class="max-w-xs mt-2 text-sm text-gray-500">
                      Covers marketplace fee,legal, escrow, and broker fees.
                      Excludes VAT of 7.5%
                    </p>
                  </div>
                </td>
                <td class="px-5 border-l border-gray-200">
                  <div class="px-5 py-5">
                    <h2 class="mt-5 font-semibold text-center text-gray-900">
                      Traditional method
                    </h2>
                    <div class="mt-1 text-center">
                      <span class="text-3xl font-semibold text-gray-900"
                        >6-10%</span
                      >
                    </div>
                    <p class="max-w-xs mt-2 text-sm text-gray-500">
                      Does not cover anything except agency. Legal etc are done
                      by yourself.
                    </p>
                  </div>
                </td>
              </tr>

              <tr>
                <td class="px-5 border-t border-gray-200 py-7">
                  Listing Price(Example)
                </td>
                <td
                  class="px-5 text-lg font-semibold text-center border-t border-l border-gray-200 py-7"
                >
                  N30,000,000
                </td>
                <td
                  class="px-5 text-lg font-semibold text-center border-t border-l border-gray-200 py-7"
                >
                  N30,000,000
                </td>
              </tr>
              <tr>
                <td
                  class="px-5 py-3 text-xl font-semibold bg-gray-100 border-t border-gray-200"
                >
                  BUYING COST
                </td>
                <td class="bg-gray-100 border-t border-gray-200"></td>
                <td class="bg-gray-100 border-t border-gray-200"></td>
              </tr>

              <tr>
                <td class="px-5 border-t border-gray-200 py-7">
                  Avg. days to bid and inspect property.
                </td>
                <td
                  class="px-5 text-lg font-semibold text-center border-t border-l border-gray-200 py-7"
                >
                  1-5 days
                </td>
                <td
                  class="px-5 text-lg font-semibold text-center border-t border-l border-gray-200 py-7"
                >
                  5-10 days
                </td>
              </tr>
              <tr>
                <td class="px-5 border-t border-gray-200 py-7">
                  Escrow
                </td>
                <td
                  class="px-5 text-lg text-center border-t border-l border-gray-200 py-7"
                >
                  <div class="flex justify-center">
                    <CheckIcon class="w-8 h-8 text-lime-600" />
                  </div>
                </td>
                <td class="px-5 text-lg border-t border-l border-gray-200 py-7">
                  <div class="flex justify-center">
                    <XIcon class="w-8 h-8 text-red-600" />
                  </div>
                </td>
              </tr>
              <tr>
                <td class="px-5 border-t border-gray-200 py-7">
                  Verification and search report fees
                </td>
                <td
                  class="px-5 text-lg font-semibold text-center border-t border-l border-gray-200 py-7"
                >
                  N0
                </td>
                <td
                  class="px-5 text-lg font-semibold text-center border-t border-l border-gray-200 py-7"
                >
                  N50,000
                </td>
              </tr>
              <tr>
                <td class="px-5 border-t border-gray-200 py-7">
                  Legal fees
                </td>
                <td
                  class="px-5 text-lg font-semibold text-center border-t border-l border-gray-200 py-7"
                >
                  N0
                </td>
                <td
                  class="px-5 text-lg font-semibold text-center border-t border-l border-gray-200 py-7"
                >
                  N50,000
                </td>
              </tr>
              <tr>
                <td class="px-5 border-t border-gray-200 py-7">
                  Data analytics and estimating returns
                </td>
                <td
                  class="px-5 text-lg text-center border-t border-l border-gray-200 py-7"
                >
                  <div class="flex justify-center">
                    <CheckIcon class="w-8 h-8 text-lime-600" />
                  </div>
                </td>
                <td class="px-5 text-lg border-t border-l border-gray-200 py-7">
                  <div class="flex justify-center">
                    <XIcon class="w-8 h-8 text-red-600" />
                  </div>
                </td>
              </tr>
              <tr>
                <td class="px-5 border-t border-gray-200 py-7">
                  Average days to close a transaction
                </td>
                <td
                  class="px-5 text-lg font-semibold text-center border-t border-l border-gray-200 py-7"
                >
                  7-14 days
                </td>
                <td
                  class="px-5 text-lg font-semibold text-center border-t border-l border-gray-200 py-7"
                >
                  14-30 days
                </td>
              </tr>
              <tr>
                <td
                  class="px-5 py-3 text-xl font-semibold bg-gray-100 border-t border-gray-200"
                >
                  TRANSACTION COST
                </td>
                <td class="bg-gray-100 border-t border-gray-200"></td>
                <td class="bg-gray-100 border-t border-gray-200"></td>
              </tr>
              <tr>
                <td class="px-5 border-t border-gray-200 py-7">
                  Roofone Fee
                </td>
                <td
                  class="px-5 text-lg font-semibold text-center border-t border-l border-gray-200 py-7"
                >
                  N900,000
                </td>
                <td class="px-5 text-lg border-t border-l border-gray-200 py-7">
                  <div class="flex justify-center">
                    <XIcon class="w-8 h-8 text-red-600" />
                  </div>
                </td>
              </tr>
              <tr>
                <td class="px-5 border-t border-gray-200 py-7">
                  Agency Fee
                </td>
                <td class="px-5 text-lg border-t border-l border-gray-200 py-7">
                  <div class="flex justify-center">
                    <XIcon class="w-8 h-8 text-red-600" />
                  </div>
                </td>
                <td
                  class="px-5 text-lg font-semibold text-center border-t border-l border-gray-200 py-7"
                >
                  N3,000,000
                </td>
              </tr>
              <tr>
                <td class="px-5 border-t border-gray-200 py-7">
                  Overall cost of buying
                </td>
                <td
                  class="px-5 text-lg font-semibold text-center border-t border-l border-gray-200 py-7"
                >
                  N900,000
                </td>
                <td
                  class="px-5 text-lg font-semibold text-center border-t border-l border-gray-200 py-7"
                >
                  N3,200,000
                </td>
              </tr>
              <tr>
                <td
                  class="px-5 text-xl font-semibold border-t border-gray-200 py-7"
                >
                  BENEFITS OF USING ROOFONE
                </td>
                <td
                  class="px-5 text-lg text-center border-t border-l border-gray-200 py-7"
                >
                  <span>N3,200,000</span><br />
                  <span>16 days</span>
                </td>
                <td
                  class="px-5 text-lg text-center border-t border-l border-gray-200 py-7"
                ></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckIcon from "@/components/icons/RegularCheckIcon";
import XIcon from "@/components/icons/XIcon";

export default {
  components: {
    CheckIcon,
    XIcon,
  },
};
</script>

<style></style>
